<template>
  <a-form
      id="formLogin"
      class="user-layout-login"
      ref="formLogin"
      :form="form"
      @submit="handleSubmit"
  >
    <!-- 错误提示 -->
    <a-alert v-if="isLoginError" type="error" showIcon :message="loginErrorMsg"/>
    <a-form-item>
      <a-input
          size="large"
          type="text"
          placeholder="手机号码"
          v-decorator="[
            'mobile',
            {rules: [{ required: true, message: '您还没有输入手机号码' }], validateTrigger: 'change'}
          ]"
      >
        <a-icon slot="prefix" type="mobile" :style="{ color: 'rgba(0,0,0,.25)' }"/>
      </a-input>
    </a-form-item>
    <a-form-item>
      <a-input
          size="large"
          type="text"
          placeholder="验证码"
          v-decorator="[
            'code',
            {rules: [{ required: true, message: '您还没有输入验证码' }], validateTrigger: 'change'}
          ]"
      >
        <a-icon slot="prefix" type="lock" :style="{ color: 'rgba(0,0,0,.25)' }"/>
        <a-button style="width: 95px" slot="addonAfter" @click="sendCode" :disabled="disabled">{{
            btn
          }}
        </a-button>
      </a-input>
    </a-form-item>
    <a-form-item>
      <a-input
          size="large"
          type="password"
          autocomplete="false"
          placeholder="请输入登录密码"
          v-decorator="[
                      'password',
                      {rules: [{ required: true, message: '您还没有输入登录密码' }], validateTrigger: 'blur'}
                    ]"
      >
        <a-icon slot="prefix" type="lock" :style="{ color: 'rgba(0,0,0,.25)' }"/>
      </a-input>
    </a-form-item>
    <div style="display: flex;justify-content: space-between">
      <a @click="setType(1)">注册账号</a>
      <a @click="setType(2)">忘记密码</a>
    </div>
    <a-form-item style="margin-top:24px">
      <a-button
          size="large"
          type="primary"
          htmlType="submit"
          class="login-button"
          :loading="state.loginBtn"
          :disabled="state.loginBtn"
      >登录
      </a-button>
    </a-form-item>
<!--    <a-form-item style="margin-top:10px">-->
<!--      <a-button-->
<!--          @click="setType(3)"-->
<!--          style="margin-top: 10px"-->
<!--          size="large"-->
<!--          class="login-button"-->
<!--      >成为管理者-->
<!--      </a-button>-->
<!--    </a-form-item>-->
  </a-form>
</template>
<script>
import {mapActions} from "vuex";
import {isEmpty, timeFix} from "@/utils/util";
import * as Api from "@/api/login";

export default {
  data() {
    return {
      type: 0,
      // 是否登录错误
      isLoginError: false,
      // 登录错误的信息
      loginErrorMsg: '登录失败',
      // 表单组件
      form: this.$form.createForm(this),
      // 页面状态
      state: {loginBtn: false},
      btn: '获取验证码',
      disabled: false,
    }
  },
  created() {
  },
  methods: {
    ...mapActions(['Login']),
    setType(type) {
      this.$emit('setType', type)
    },
    async sendCode() {
      const mobile = this.form.getFieldValue('mobile')
      if (isEmpty(mobile)) {
        this.$message.error('请先输入手机号码')
        return
      }
      var s = 60
      const d = setInterval(() => {
        if (s < 0) {
          clearInterval(d)
          this.disabled = false
          this.btn = '获取验证码'
        } else {
          this.btn = s + 's'
          s--
        }
      }, 1000)
      this.disabled = true
      const {message} = await Api.sendCode({mobile, code_name: 'login'})
      this.$message.success(message)
    },
    /**
     * 表单提交: 确定登录
     */
    handleSubmit(e) {
      e.preventDefault()
      const {
        form: {validateFields},
        state,
        Login
      } = this
      state.loginBtn = true
      // 表单验证
      validateFields(['mobile', 'code', 'password'], {force: true}, (err, values) => {
        if (!err) {
          const loginParams = {...values, code_name: 'login'}
          Login(loginParams)
              .then(res => this.loginSuccess(res))
              .catch(err => this.loginFailed(err))
              .finally(() => {
                state.loginBtn = false
              })
        } else {
          setTimeout(() => {
            state.loginBtn = false
          }, 100)
        }
      })
    },

    /**
     * 登录成功
     */
    loginSuccess(res) {
      this.isLoginError = false
      // 显示提示信息
      this.$message.success(res.message, 1.5)
      setTimeout(() => {
        // 跳转到后台首页
        this.$router.push({path: '/'})
        // 显示欢迎信息
        this.$notification.success({
          message: '欢迎',
          description: `${timeFix()}，欢迎回来`
        })
      }, 1000)
    },

    /**
     * 登录请求失败
     */
    loginFailed(response) {
      this.isLoginError = true
      this.loginErrorMsg = response.message
    }
  }
}

</script>
<style scoped lang="less">
.user-layout-login {
  label {
    font-size: 14px;
  }

  /deep/ .login-input .ant-input {
    border: none;
    background: rgba(235, 237, 242, 0.4);
    border-radius: 5px;
    font-size: 14px;
  }

  /deep/ .ant-form-explain {
    margin-top: 5px;
    // color: rgba(245, 34, 45, 0.8);
    //color: rgba(0, 0, 0, 0.45);
  }

  .login-button {
    padding: 0 15px;
    font-size: 16px;
    height: 40px;
    width: 100%;
    margin-top: 20px;
    box-shadow: 0px 4px 16px 0px rgba(34, 185, 255, 0.15);

    &:hover {
      box-shadow: 0px 9px 16px 0px rgba(34, 185, 255, 0.25) !important;
    }
  }

  .ant-alert-error {
    margin-bottom: 24px;
    background-color: #fffbfb;
  }
}
</style>
